import { Text, View } from 'react-native';
import React from 'react';

import { styles } from './EventFeeInfo.styles';
import styled from '@gf/cross-platform-lib/styled-components';

const EventFeeInfoContainer = styled(View)`
  ${styles.eventFeeInfoContainer}
`;

const EventFeeInfoText = styled(Text)`
  ${styles.eventFeeInfoText}
`;

interface EventFeeInfoProps {
  hasForm: boolean;
  hasHiddenFees: boolean;
}

export const EventFeeInfo = ({ hasForm, hasHiddenFees }: EventFeeInfoProps) => {
  return (
    <EventFeeInfoContainer>
      {hasForm && <EventFeeInfoText>Additional information will be required to complete purchase.</EventFeeInfoText>}
      {hasHiddenFees && <EventFeeInfoText>Additional fees may apply.</EventFeeInfoText>}
      <EventFeeInfoText>All purchases are non-refundable.</EventFeeInfoText>
    </EventFeeInfoContainer>
  );
};

export default EventFeeInfo;

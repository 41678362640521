import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  eventTicketItemContainer: css<{
    ticketColor: string;
    isNearWhite: boolean;
    isMobile: boolean;
    isTextWrapped: boolean;
    isFirstTicket: boolean;
  }>`
    border-top-color: #ebebeb;
    border-right-color: #ebebeb;
    border-bottom-color: #ebebeb;
    border-left-color: ${({ ticketColor = '#000000', isNearWhite }) => {
      const color = ticketColor || '#000000';
      return isNearWhite ? '#ebebeb' : color;
    }};
    border-width: 1px;
    border-left-width: ${({ isNearWhite }) => (isNearWhite ? '1px' : '5px')};
    border-radius: 4px;
    border-type: solid;
    margin-top: ${({ isFirstTicket, isMobile }) => (isFirstTicket ? '16px' : isMobile ? '8px' : '16px')};
    padding-top: ${({ isTextWrapped }) => (isTextWrapped ? '4px' : '16px')};
    padding-right: 16px;
    padding-bottom: ${({ isTextWrapped }) => (isTextWrapped ? '4px' : '16px')};
    padding-left: 16px;
  `,
  eventTicketTextContainer: css<{ isMobile: boolean }>`
    height: 100%;
    justify-content: ${({ isMobile = false }) => (isMobile ? 'flex-start' : 'center')};
    flex-direction: column;
    flex: 4;
  `,
  eventNotAccessContainer: css`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  `,
  lowTicketWarningWrapper: css<{ isMobile: boolean }>`
    margin-right: ${({ isMobile = false }) => (isMobile ? 0 : '16px')};
    padding-top: ${({ isMobile }) => (isMobile ? '8px' : '16px')};
  `,
  lowTicketWarningContainer: css<{ isMobile: boolean }>`
    display: flex;
    justify-content: center;
    align-items: ${({ isMobile = false }) => (isMobile ? 'flex-end' : 'center')};
    flex-direction: ${({ isMobile = false }) => (isMobile ? 'column-reverse' : 'row')};
  `
};
